import React, { useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { motion } from "framer-motion";
import emailjs, { init } from "emailjs-com";
import { Helmet } from "react-helmet";

import BackgroundImage from "../images/backgroundImage-min.jpg";
import TabletImage from "../images/backgroundImage.jpg";
import MobileBackgroundImage from "../images/backgroundImage.jpg";
import Logo from "../svg/logo.svg";
import MobileLogo from "../svg/mobileLogo.svg";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;
    min-height:100%; 
    position:relative; 
    
  }
`;

const PageContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  background-color: linear-gradient(270deg, #eb5b1f 0%, #f39200 100%);
  min-height: 100vh;
  height: 100%;

  overflow-x: hidden;

  color: #fff;
  font-family: "Roboto", sans-serif, serif;
  min-height: 100vh;

  @media (max-width: 1000px) {
    width: 100vw;
    background-position: center right;
    background-image: unset;
  }
`;

const OuterContentContainer = styled.div`
  height: 100vh;
  width: 100vw;
  z-index: 2;
`;

const HeaderContainer = styled(motion.div)`
  display: flex;
  width: 100%;
  height: 144px;
  justify-content: center;
  align-items: center;
  z-index: 2;
  padding: 0px 120px;
  width: calc(100vw - 240px);
  @media (max-width: 1300px) {
    padding: 0px 60px;
    width: calc(100vw - 120px);
  }
  @media (max-width: 1000px) {
    height: 60px;
    padding: 0px 20px;
    width: calc(100vw - 40px);

    width: 100%;
    justify-content: flex-start;
  }
`;

const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  max-width: 1440px;
  @media (max-width: 1000px) {
    display: none;
  }
`;

const MobileLogoContainer = styled.div`
  display: none;
  @media (max-width: 1000px) {
    display: block;
  }
`;

const ContentContainerOuter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 207px);
  height: 100%;
  padding: 0px 120px;
  width: calc(100vw - 240px);

  @media (max-width: 1300px) {
    padding: 0px 60px;
    width: calc(100vw - 120px);
  }
  @media (max-width: 1000px) {
    min-height: calc(100vh - 123px);
    padding: 0px;
    width: 100%;
  }
`;

const ContentContainer = styled.div`
  z-index: 2;
  max-width: 1550px;
  display: flex;
  justify-content: flex-start;
  max-width: 1440px;
  width: 100%;
  flex-direction: column;
  @media (max-width: 1300px) {
    max-width: 1300px;
  }

  @media (max-width: 1000px) {
    text-align: center;
    justify-content: center;
    align-items: center;

    padding: 0px 20px;
    width: calc(100vw - 40px);
  }
  @media (max-width: 700px) {
    text-align: left;
    justify-content: flex-start;
    align-items: flex-start;
  }
  @media (max-width: 1000px) {
    padding: 0px;
  }
`;

const BackgroundImageContainerOuter = styled.div`
  z-index: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

const BackgroundImageContainer = styled.div`
  background-color: linear-gradient(270deg, #eb5b1f 0%, #f39200 100%);
  background-image: url(${(props) => props.bgImage});
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
`;

const Title = styled(motion.div)`
  font-weight: 300;
  font-size: 64px;
  line-height: 75px;
  max-width: 855px;
  width: 100%;
  font-feature-settings: "kern" off, "liga" off;
  @media (max-width: 1300px) {
    max-width: 700px;
    font-size: 55px;
    line-height: 60px;
  }
  @media (max-width: 1240px) {
    max-width: 650px;
    font-size: 52px;
    line-height: 60px;
  }
  @media (max-width: 1100px) {
    max-width: 550px;
    font-size: 44px;
    line-height: 54px;
  }
  @media (max-width: 1050px) {
    font-size: 38px;
    max-width: 500px;
  }
  @media (max-width: 1000px) {
    max-width: 700px;
  }
  @media (max-width: 700px) {
    font-size: 36px;
    line-height: 42px;
  }
`;

const Subtitle = styled(motion.div)`
  font-size: 18px;
  line-height: 32px;
  font-feature-settings: "kern" off, "liga" off;
  @media (max-width: 1300px) {
    max-width: 600px;
  }
  @media (max-width: 1050px) {
    max-width: 500px;
  }
  @media (max-width: 1000px) {
    font-size: 16px;
  }
  @media (max-width: 700px) {
    font-size: 12px;
    line-height: 24px;
    margin-top: 20px;
  }
`;

const EmailContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 43px;
  @media (max-width: 700px) {
    width: 100%;
  }
  padding-bottom: 20px;
`;

const EmailInput = styled(motion.input)`
  width: 450px;
  background-color: transparent;
  border-style: solid;
  border-width: 0px;
  border-bottom-width: 1px;
  border-color: #fff;
  color: #fff;
  font-size: 20px;
  line-height: 23px;
  padding: 10px 5px;
  outline: none;
  font-weight: 300;
  border-radius: 0px;
  ::placeholder,
  ::-webkit-input-placeholder {
    font-size: 20px;
    line-height: 23px;
    color: #fff;
    font-weight: 300;
  }
  @media (max-width: 700px) {
    width: calc(100% - 20px);
  }
`;

const EmailButton = styled(motion.button)`
  background: #ffffff;
  color: #000;
  border-radius: 2px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  padding: 14.5px 33px;
  width: fit-content;
  border-style: solid;
  border-width: 0;
  margin-top: 27px;
  cursor: pointer;
  @media (max-width: 1000px) {
    margin-top: 0px;
  }
`;

const Footer = styled(motion.div)`
  padding-left: 120px;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  background: linear-gradient(270deg, #eb5b1f 0%, #f39200 100%);
  height: 63px;
  display: flex;
  align-items: center;
  padding: 0;
  z-index: 3;
  @media (max-width: 1000px) {
    padding: 0px 20px 0px 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  a {
    text-decoration: none;
    color: #ffffff;
  }
`;

const FooterInner = styled.div`
  margin-left: 120px;
  @media (max-width: 1000px) {
    margin: 0;
    max-width: 215px;
  }
`;

const SubmittedText = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  width: 270px;
  margin-top: 33px;
`;

const ErrorMessage = styled.div`
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  margin-top: 20px;
`;

const PageAnim = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.7,
    },
  },
};

const LogoAnim = {
  hidden: { opacity: 0, y: 10 },
  visible: {
    opacity: 1,
    transition: {
      delay: 1.1,
    },
  },
};

const TitleAnim = {
  hidden: { opacity: 0, y: 10 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 1.2,
    },
  },
};

const TextAnim = {
  hidden: { opacity: 0, y: 10 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 1.25,
    },
  },
};

const EmailInputAnim = {
  hidden: { opacity: 0, y: 10 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 1.4,
    },
  },
};

const EmailButtonAnim = {
  hidden: { opacity: 0, y: 10 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 1.5,
    },
  },
};

const FooterAnim = {
  hidden: { opacity: 0, y: 10 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 1.6,
    },
  },
};

function Index() {
  const [emailInput, setEmailInput] = useState("");
  const [submittedForm, setSubmittedForm] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const anim = true;

  init("user_k8uMPgl3mPR1ff01gbwln");

  const formSubmit = () => {
    if (emailInput === "") {
      setErrorMessage("Whoops - you'll need to enter an email address first!");
    } else if (!emailInput.includes("@")) {
      setErrorMessage("Sorry - that doesn't look like a valid email address");
    } else {
      setSubmittedForm(true);
      setErrorMessage("");
      emailjs
        .send("service_y0vsq6t", "template_zci6i5y", {
          email: emailInput,
        })
        .then(
          (result) => {
            console.log("Submitted Email", result);
          },
          (error) => {
            console.log(error);
          }
        );
    }
  };

  return (
    <PageContainer animate={"visible"} initial={"hidden"} variants={PageAnim}>
      <Helmet>
        <title>LeadersInc</title>
        <meta name="title" content="LeadersInc" />
        <meta
          name="description"
          content="Learn from the best and reach your potential."
        />
        <meta name="keywords" content="LeadersInc" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
      </Helmet>
      <GlobalStyle />
      <HeaderContainer
        animate={anim ? "visible" : "hidden"}
        initial={"hidden"}
        variants={LogoAnim}
      >
        <LogoContainer>
          <Logo />
        </LogoContainer>
        <MobileLogoContainer>
          <MobileLogo />
        </MobileLogoContainer>
      </HeaderContainer>
      <BackgroundImageContainerOuter>
        <BackgroundImageContainer bgImage={BackgroundImage} />
      </BackgroundImageContainerOuter>

      <ContentContainerOuter>
        <ContentContainer>
          <Title animate={"visible"} initial={"hidden"} variants={TitleAnim}>
            Learn from the best and reach your potential.
          </Title>
          <Subtitle animate={"visible"} initial={"hidden"} variants={TextAnim}>
            <p>
              Join an exclusive group of <b>leaders</b> from your sector and
              learn with some of the best business brains in the world.
              <br />
              If you would like to enquire about a free trial of our
              LeadersInLegal group, enter your details below.
            </p>
          </Subtitle>

          <EmailContainer>
            <EmailInput
              animate={"visible"}
              initial={"hidden"}
              variants={EmailInputAnim}
              placeholder={"Your Email"}
              type={"email"}
              value={emailInput}
              onChange={(e) => setEmailInput(e.target.value)}
              type="text"
              name="Email Address"
            />
            <ErrorMessage>{errorMessage}</ErrorMessage>
            {submittedForm ? (
              <SubmittedText>
                Thank you! A member of our team will be in touch to discuss your
                free trial.
              </SubmittedText>
            ) : (
              <EmailButton
                animate={"visible"}
                initial={"hidden"}
                variants={EmailButtonAnim}
                onClick={() => formSubmit()}
              >
                Enquire
              </EmailButton>
            )}
          </EmailContainer>
        </ContentContainer>
      </ContentContainerOuter>
      <Footer animate={"visible"} initial={"hidden"} variants={FooterAnim}>
        <FooterInner>
          To find out more about LEADERSINC please contact us on{" "}
          <a href="tel: 01372 372 372">01372 372 372</a>
        </FooterInner>
      </Footer>
    </PageContainer>
  );
}

export default Index;
